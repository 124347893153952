import React from "react"
import styled from "styled-components"
import useNavigation from "../../hooks/useNavigation"

const PlanCard = ({ plan }) => {
  const { setRegulationModal, setSpeedPlan } = useNavigation()

  return (
    <PlanContainer>
      <Header forBusiness={plan.forBusiness}>{plan.for}</Header>
      <Content>
        <Speed>
          {plan.speed}
          <span>mb</span>
        </Speed>
        <PriceWrapper>
          <PriceValue>R$ {plan.value}</PriceValue>
        </PriceWrapper>
        {/* // eslint-disable-next-line react/jsx-one-expression-per-line */}
        <Small>({plan.small})</Small>
        <Rate forBusiness={plan.forBusiness} hasTax={plan.taxa}>
          {plan.taxa}
        </Rate>
        <ShowRules
          onClick={() => {
            setSpeedPlan(plan.speed)
            setRegulationModal(plan.regulation)
          }}
        >
          ver regulamento
        </ShowRules>
        <Signed href="https://assine.jnnet.com.br">
          <span>assine agora</span>
        </Signed>
      </Content>
    </PlanContainer>
  )
}

const PlanContainer = styled.article`
  width: 260px;
  min-height: 296px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  margin: 15px;
`

const Speed = styled.h1`
  color: #132e37;
  font-weight: bold;
  font-size: 28px;
  font-size: 80px;
  letter-spacing: -6px;
  text-align: center;

  & span {
    font-weight: normal;
    font-size: 28px;
    text-transform: uppercase;
  }
`

const Content = styled.section`
  padding: 20px;
`

const PriceWrapper = styled.section`
  border-radius: 13px;
  padding: 15px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  & p {
    line-height: 1.5;
  }
`

const Header = styled.header`
  background-color: ${({ theme, forBusiness }) =>
    forBusiness ? theme.colors.primary : theme.colors.secondary};
  width: 100%;
  border-radius: 13px 13px 0 0;
  height: 35px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
`

const PriceLabel = styled.p`
  font-size: 17px;
  font-weight: medium;
`

const PriceValue = styled.p`
  font-size: 37px;
  font-weight: bold;
`

const Small = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  margin: 5px 0;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
`

const Rate = styled.p`
  margin: 13px 0;
  font-size: 18px;
  text-align: center;
  color: ${({ forBusiness, hasTax, theme }) =>
    forBusiness && hasTax !== "ADESÃO GRÁTIS"
      ? theme.colors.primary
      : "#0bbc15"};
`

const ShowRules = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  display: block;
  margin: 5px 0 10px;
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`

const Signed = styled.a`
  height: 35px;
  width: 153px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 26px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0 auto;
  text-decoration: none;
`

export default PlanCard
