export const STATIC_PLANS = [
  {
    for: "Para minha casa",
    speed: 200,
    value: "54,90",
    small: "para os primeiros 2 meses",
    signed: "",
    regulation: "fisic",
    taxa: "ADESÃO GRÁTIS",
    forBusiness: false,
  },
  {
    for: "Para minha casa",
    speed: 300,
    value: "84,90",
    small: "para os primeiros 2 meses",
    signed: "",
    regulation: "fisic",
    taxa: "ADESÃO GRÁTIS",
    forBusiness: false,
  },

  {
    for: "Para minha casa",
    speed: 400,
    value: "104,90",
    small: "para os primeiros 12 meses",
    signed: "",
    regulation: "fisic",
    taxa: "ADESÃO GRÁTIS",
    forBusiness: false,
  },

  //
  {
    for: "Para o meu negócio",
    speed: 260,
    value: "99,90",
    small: "para os primeiros 12 meses",
    signed: "",
    regulation: "pj",
    taxa: "ADESÃO GRÁTIS",
    forBusiness: true,
  },

  {
    for: "Para o meu negócio",
    speed: 560,
    value: "119,90",
    small: "para os primeiros 12 meses",
    signed: "",
    regulation: "pj",
    taxa: "ADESÃO GRÁTIS",
    forBusiness: true,
  },
]

/**
 * TODO: call API after defined CMS
 * by @antunes
 */
export const getPlans = async () => {
  //
}
